import React from "react"
import HomeContainer from "../containers/HomeContainer"
import HeadData from "../data/HeadData"
import SkewedHeaderBg from "../headers/SkewedHeaderBg"
import PageDemoFullHeaderColorBg from '../headers/PageDemoFullHeaderColorBg'
import HeaderNav from '../navigation/HeaderNav'
import PageNotFoundText from "../headers/PageNotFoundText"

export default () => (
  <HomeContainer>
    <HeadData title='Page Not Found' />

    <SkewedHeaderBg />

    <PageDemoFullHeaderColorBg>
      <HeaderNav isHome />
      <PageNotFoundText />
    </PageDemoFullHeaderColorBg>
  </HomeContainer>
)
