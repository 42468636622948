import React from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledDiv = styled.div`
  z-index: 50;

  .text-40 {
    font-size: 40px;

    @media (max-width:767px) {
      font-size: 25px;
    }
  }
`

const PageNotFoundText = () => (
  <StyledDiv className='sm:w-full md:w-11/12 lg:w-10/12 xl:w-8/12 flex sm:flex-col items-center mt-20 xl:mt-24 mx-auto sm:p-10 md:py-10'>
    <div className='sm:w-full md:w-full w-5/12'>
      <h1 className='font-display text-40 text-grolens-green-900 font-extrabold leading-tight mb-6'>
        404
      </h1>

      <h2 className='sm:w-full md:w-full w-3/4 font-body font-medium text-xl sm:text-lg text-grolens-green-800 mb-6 sm:mb-8'>
        The page you are looking for can’t be found.
      </h2>

      <Link to='/'>
        <button className="bg-grolens-green-300 text-white text-md font-medium uppercase py-4 px-12">
          Home
        </button>
      </Link>
    </div>
  </StyledDiv>
)

export default PageNotFoundText