import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 800px;
  background: #f3fbf9;
  z-index: 10;
`

const PageDemoFullHeaderColorBg = ({ children }) => (
  <StyledDiv>
    {children}
  </StyledDiv>
)

PageDemoFullHeaderColorBg.propTypes = {
  children: PropTypes.node,
}

export default PageDemoFullHeaderColorBg